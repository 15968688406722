export const addFormItem = [
    {
        label: "真实姓名:",
        prop: "realName",
        type: "input",
        placeholder: "请输入真实姓名"
    },
    {
        label: "手机号:",
        prop: "mobile",
        type: "input",
        readonly: true,
        placeholder: "请输入手机号"
    },
    {
        label: "登陆用户名:",
        prop: "loginAccount",
        type: "input",
        placeholder: "请输入登录用户名"
    },
    {
        label: "分成比例(%):",
        prop: "holderRatio",
        type: "inputNumber",
        step: 0.1,
        precision: 1,
        max: 100
    },
    {
        label: "地址:",
        prop: "address",
        type: "input",
        placeholder: "请输入分成者住址",
        
    },
    {
        label: "订购日期",
        prop: "buyTime",
        type: "date",
        placeholder: "选择分成者入驻日期",
        dateFormat: "yyyy-MM-dd"
    },
]
export const editFormItem = [
    {
        label: "登陆用户名:",
        prop: "useraccount",
        type: "input",
        placeholder: "请输入登录用户名",
        disabled:true,
    },
    {
        label: "手机号:",
        prop: "mobile",
        type: "input",
        placeholder: "请输入手机号"
    },
    {
        label: "真实姓名:",
        prop: "realName",
        type: "input",
        placeholder: "请输入真实姓名"
    },
    {
        label: "分成比例(%):",
        prop: "holderRatio",
        type: "inputNumber",
        step: 0.1,
        precision: 1,
        max: 100
    },
    {
        label: "地址:",
        prop: "address",
        type: "input",
        placeholder: "请输入分成者住址",
        
    }
]