<template>
  <el-dialog
    title="告警处理"
    :visible.sync="visible"
    width="550px"
    :close-on-click-modal="false"
    :before-close="handleBeforeClose"
  >
    <div>
      <div
        class="error-box"
        :style="{ backgroundColor: alarmBgColor(currentRow.level) }"
      >
        <div class="error-header">
          <img
            :src="alarmIcon(currentRow.level)"
            class="error-icon"
            mode="scaleToFill"
          />
          <div class="error-label-container">
            <div class="error-label">
              <div class="error-title">{{ currentRow.alertName || '-' }}</div>
              <LightTag
                v-if="currentRow.level"
                :type="alarmLevelTypeFormatter(currentRow.level)"
                :text="alarmLevelFormatter(currentRow.level)"
              ></LightTag>
            </div>
            <div class="error-time">
              告警时间：{{ currentRow.createTime || '-' }}
            </div>
          </div>
        </div>
        <div class="info-box">
          <div class="info-box-item">
            <div class="info-box-item-label">是否已恢复</div>
            <div class="info-box-item-value" v-overflow-tooltip>
              {{ currentRow.isRestored ? '是' : '否' }}
            </div>
          </div>
          <div class="info-box-item">
            <div class="info-box-item-label">充电站名称</div>
            <div class="info-box-item-value" v-overflow-tooltip>
              {{ currentRow.plotName || '-' }}
            </div>
          </div>
          <div class="info-box-item">
            <div class="info-box-item-label">设备名称</div>
            <div class="info-box-item-value" v-overflow-tooltip>
              {{ currentRow.deviceName || '-' }}
            </div>
          </div>
        </div>
      </div>
      <div class="form-box">
        <el-form
          ref="submitFormRef"
          :model="submitForm"
          label-width="80px"
          style="width: 100%;"
          label-position="top"
          size="mini"
          :rules="rules"
        >
          <el-form-item label="处理结果" prop="processResult">
            <el-radio-group v-model="submitForm.processResult">
              <el-radio label="1">已处理</el-radio>
              <el-radio label="2" v-if="currentRow.processResult === 0"
                >挂起</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item label="恢复原因" prop="restoredReason">
            <el-radio-group v-model="submitForm.restoredReason">
              <el-radio label="设备已恢复但未上传恢复"></el-radio>
              <el-radio label="无需处理可忽略"></el-radio>
              <el-radio label="人工处理"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="处理说明" prop="operatorDetail">
            <el-input
              type="textarea"
              v-model="submitForm.operatorDetail"
              placeholder="请输入 "
              maxlength="150"
              :rows="3"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-form>
      </div>

      <span class="drawer-footer">
        <div class="drawer-footer-inner">
          <el-button
            type=""
            @click="
              handleBeforeClose(() => {
                visible = false;
              })
            "
            >取消</el-button
          >
          <el-button type="primary" @click="debouncedHandleButtonClick"
            >确定</el-button
          >
        </div>
      </span>
    </div>
  </el-dialog>
</template>

<script setup>
import { ref } from 'vue';
import { ALARM_LEVEL, findLabelByValue } from '@/utils/const';
import LightTag from '@/components/LightTag/index.vue';
import { alertHandle } from '@/api/alert';
import { Message } from 'element-ui';
import { debounce } from 'lodash-es';

let prop = defineProps({
  title: {
    type: String,
    default: '告警详情',
  },
});

function alarmLevelFormatter(val) {
  return findLabelByValue(ALARM_LEVEL, val);
}
function alarmLevelTypeFormatter(val) {
  return findLabelByValue(ALARM_LEVEL, val, 'type');
}
let iconOption = {
  1: require('@/assets/alarm/warning-error.png'),
  2: require('@/assets/alarm/warning-warning.png'),
  3: require('@/assets/alarm/warning-normal.png'),
};
let bgColorOption = {
  1: 'rgba(223, 55, 55, 0.08)',
  2: 'rgba(248, 119, 0, 0.08)',
  3: 'rgba(5, 124, 152, 0.08)',
};
function alarmIcon(val) {
  return iconOption[val] || require('@/assets/alarm/warning-normal.png');
}
function alarmBgColor(val) {
  return bgColorOption[val] || 'rgba(5, 124, 152, 0.08)';
}

const visible = ref(false);

const currentRow = ref({});
function open(row) {
  currentRow.value = row;
  visible.value = true;
}
function close() {
  visible.value = false;
}
defineExpose({ open, close });
const emit = defineEmits(['change']);

function handleBeforeClose(done) {
  submitFormRef.value.resetFields();
  done();
}

// 表单
const submitForm = ref({
  processResult: '1',
  restoredReason: '人工处理',
  operatorDetail: '',
});

const rules = {
  processResult: [
    {
      required: true,
      message: '请选择处理结果',
      trigger: 'change',
    },
  ],
  restoredReason: [
    {
      required: true,
      message: '请选择恢复原因',
      trigger: 'change',
    },
  ],
};

const submitFormRef = ref();
function handleConfirm() {
  submitFormRef.value.validate((valid) => {
    if (valid) {
      alertHandle({
        eventId: [currentRow.value.eventId],
        ...submitForm.value,
      })
        .then((res) => {
          if (res.message.code == 0) {
            visible.value = false;
            submitFormRef.value.resetFields();
            currentRow.value = {};
            emit('change');
            Message.success('操作成功');
          } else {
            Message.error(res.message.message);
          }
        })
        .catch((e) => {
          console.log('🚀 ~ file: handleDialog.vue:208 ~ e:', e);
        });
    }
  });
}

const debouncedHandleButtonClick = debounce(
  () => {
    handleConfirm();
  },
  800,
  {
    leading: true,
    trailing: false,
  }
);
</script>

<style lang="scss" scoped>
.error-box {
  display: flex;
  padding: 24px 24px 32px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-top: 1px solid #e5e6eb;
  background: rgba(5, 124, 152, 0.08);
  .error-header {
    display: flex;
    align-items: center;
    gap: 12px;
    .error-label-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 4px;
      flex: 1 0 0;
    }
    .error-icon {
      width: 54px;
      height: 54px;
      flex-shrink: 0;
    }
    .error-label {
      display: flex;
      align-items: center;
      gap: 4px;
      .error-title {
        max-width: 350px;
        color: var(--PC-, #1d2129);
        font-family: 'PingFang SC';
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
    .error-time {
      color: var(--PC-, #4e5969);
      font-family: 'PingFang SC';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
.info-box {
  display: flex;
  padding: 12px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px 32px;
  align-self: stretch;
  flex-wrap: wrap;
  border-radius: 8px;
  border: 2px solid #fff;
  background: rgba(255, 255, 255, 0.65);
  .info-box-item {
    display: flex;
    min-width: 200px;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;
  }
  .info-box-item-label {
    color: var(--PC-, #86909c);
    font-family: 'PingFang SC';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .info-box-item-value {
    overflow: hidden;
    color: var(--PC-, #1d2129);
    text-overflow: ellipsis;
    font-family: 'PingFang SC';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    flex: 1;
  }
}
.drawer-footer {
  display: flex;
  height: 53px;
  border-top: 1px solid var(--PC--, #e5e6eb);
  padding: 10px 0;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  align-self: stretch;
  .drawer-footer-inner {
    display: flex;
    padding: 0px 24px;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    align-self: stretch;
  }
}
.form-box {
  display: flex;
  padding: 24px 24px 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  position: relative;
  z-index: 61;
  top: -16px;
  background: #fff;
  border-radius: 8px;
}
::v-deep .el-dialog {
  border-radius: 8px;
  background: var(--PC-, #fff);
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: var(--PC--, #1d2129);
}
::v-deep .el-dialog__title {
  color: var(--PC-, #1d2129);
  font-family: 'PingFang SC';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
::v-deep .el-dialog__header {
  box-shadow: 0px 1px 0px 0px var(--PC--, #e5e6eb);
  padding: 16px 24px 8px 24px;
}
::v-deep .el-dialog__body {
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 16px;
}
::v-deep .el-form-item__label {
  color: var(--PC-, #1d2129);
}
.el-radio {
  color: var(--PC-, #1d2129);
}
::v-deep .el-form--label-top .el-form-item__label {
  padding-bottom: 0;
}
</style>
