var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "编辑",
        visible: _setup.visible,
        width: "550px",
        "close-on-click-modal": false,
        "before-close": _setup.handleBeforeClose,
      },
      on: {
        "update:visible": function ($event) {
          _setup.visible = $event
        },
      },
    },
    [
      _c("div", [
        _c(
          "div",
          {
            staticClass: "error-box",
            style: {
              backgroundColor: _setup.alarmBgColor(_setup.currentRow.level),
            },
          },
          [
            _c("div", { staticClass: "error-header" }, [
              _c("img", {
                staticClass: "error-icon",
                attrs: {
                  src: _setup.alarmIcon(_setup.currentRow.level),
                  mode: "scaleToFill",
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "error-label-container" }, [
                _c("div", { staticClass: "error-label" }, [
                  _c("div", { staticClass: "error-title" }, [
                    _vm._v(_vm._s(_setup.currentRow.name || "-")),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "info-suffix" }, [
                  _c("div", { staticClass: "info-item" }, [
                    _c("div", { staticClass: "info-label" }, [
                      _vm._v("设备类型"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "info-value" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _setup.deviceTypeFormatter(
                              _setup.currentRow.deviceType
                            )
                          ) +
                          "\n              "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "info-item" }, [
                    _c("div", { staticClass: "info-label" }, [
                      _vm._v("告警码"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "info-value" }, [
                      _vm._v(_vm._s(_setup.currentRow.code || "-")),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-box" },
          [
            _c(
              "el-form",
              {
                ref: "submitFormRef",
                staticStyle: { width: "100%" },
                attrs: {
                  model: _setup.submitForm,
                  "label-width": "80px",
                  "label-position": "top",
                  size: "mini",
                  rules: _setup.rules,
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "告警等级", prop: "level" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _setup.submitForm.level,
                          callback: function ($$v) {
                            _vm.$set(_setup.submitForm, "level", $$v)
                          },
                          expression: "submitForm.level",
                        },
                      },
                      _vm._l(_setup.ALARM_LEVEL, function (item) {
                        return _c(
                          "el-radio",
                          { key: item.value, attrs: { label: item.value } },
                          [_vm._v(_vm._s(item.label))]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "告警说明", prop: "description" } },
                  [
                    _c("el-input", {
                      attrs: {
                        type: "textarea",
                        placeholder: "请输入 ",
                        maxlength: "100",
                        rows: 3,
                        "show-word-limit": "",
                      },
                      model: {
                        value: _setup.submitForm.description,
                        callback: function ($$v) {
                          _vm.$set(_setup.submitForm, "description", $$v)
                        },
                        expression: "submitForm.description",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("span", { staticClass: "drawer-footer" }, [
          _c(
            "div",
            { staticClass: "drawer-footer-inner" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "" },
                  on: {
                    click: function ($event) {
                      _setup.handleBeforeClose(() => {
                        _setup.visible = false
                      })
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _setup.debouncedHandleButtonClick },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }