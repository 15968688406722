import axios from "axios";
import { MessageBox, Message, Loading } from "element-ui";
import store from "@/store";
import { getToken } from "@/utils/auth";
let loading;
let needLoadingRequestCount = 0;
function showFullScreenLoading() {
  if (needLoadingRequestCount === 0) {
    startLoading();
  }
  needLoadingRequestCount++;
}

function tryHideFullScreenLoading() {
  if (needLoadingRequestCount <= 0) return;
  needLoadingRequestCount--;
  if (needLoadingRequestCount === 0) {
    endLoading();
  }
}
function startLoading() {
  loading = Loading.service({
    lock: true,
    text: "加载中……",
    background: "rgba(0, 0, 0, 0.7)",
  });
}

function endLoading() {
  loading.close();
}
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  //  baseURL: "http://localhost:8089", // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 100000, // request timeout
  retry: 3,
  retryDelay: 10000,
  showLoading: true,
});
service.interceptors.request.use(
  (config) => {
    if (config.showLoading) {
      //showFullScreenLoading()
    }
    // do something before request is sent

    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    config.headers.token = getToken();

    return config;
  },
  (error) => {
    // do something with request error
    return Promise.reject(error);
  }
);
// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data;

    if (response.config.showLoading) {
      tryHideFullScreenLoading();
    }
    if (res.message && res.message.code === 201) {
      store.dispatch("user/logout").then(() => {
        location.reload();
      });
    }
    if (res.message && res.message.status !== 200) {
      Message({
        message: res.message || "Error",
        type: "error",
        duration: 5 * 1000,
      });

      return Promise.reject(new Error(res.message || "Error"));
    } else {
      if (res.message && res.message.code !== 0) {
        if (response.config.showError) {
          Message({
            message: res.message || "Error",
            type: "error",
            duration: 5 * 1000,
          });
          if (res.message.status === 500) {
            Message({
              message: res.message || "网络繁忙，请稍后重试",
              type: "error",
              duration: 5 * 1000,
            });
          }
          return Promise.reject(new Error(res.message || "Error"));
        }
      }
      return res;
    }
  },
  (error) => {
    if (error.config?.showLoading) {
      tryHideFullScreenLoading();
    }
    if (error?.response?.data?.message?.status === 500) {
      Message({
        message: "网络繁忙，请稍后重试",
        type: "error",
        duration: 5 * 1000,
      });
    }
    if (error.response?.data?.message) {
      return Promise.reject(error.response.data.message.message);
    }
    return Promise.reject(error);
  }
);
export default service;
