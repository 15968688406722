<template>
  <div class="main">
    <div class="content-box">
      <el-form
        :model="form"
        ref="form"
        label-width="0"
        :inline="true"
       
      >
        <el-form-item label="">
          <el-input
            v-model="form.searchKey"
            placeholder="搜索子账户名称"
            maxlength="16"
          >
            <template #suffix>
              <div>
                <el-button @click="onSubmit" type="primary">搜索</el-button>
              </div>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            slot="suffix"
            @click="onSubmit"
            type="primary"
            icon="el-icon-search"
            style="border-radius: 0 4px 4px 0; margin-right: -5px"
            >搜索</el-button
          >

          <el-button
            @click="addHandler"
            type="primary"
            style="margin-left: 20px"
            icon="el-icon-plus"
            >新建分成者</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <el-table :data="tableData" border style="width: 100%;">
        <el-table-column
          align="center"
          v-for="col in tableColumn"
          :prop="col.prop"
          :key="col.id"
          :label="col.label"
          :width="col.width"
          :min-width="col.minWidth"
        >
        </el-table-column>
        <el-table-column label="分成者状态" width="100px" align="center">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.holderStatus"
              @change="holderStatusChange(scope.row)"
              :active-value="1"
              :inactive-value="2"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="160px" align="center">
          <template slot-scope="scope">
            <el-button @click="resetPassword(scope.row.shareholder)"
              >重置密码</el-button
            >
            <el-button type="primary" @click="editCardHandler(scope.row)"
              >编辑</el-button
            >
            <!-- <el-button type="text"  >查看</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        layout="prev, pager, next,total"
        :total="tableTotal"
        :page-size="form.pageSize"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>

    <el-dialog
      :title="isAdd ? '新增' : '编辑'"
      :visible.sync="dialogVisible"
      width="40%"
    >
      <el-form
        ref="form"
        :model="formData"
        label-width="130px"
      >
        <div class="topTip">
          <span>当前剩余分成比例：</span>
          <span>{{ surplusRadio }}%</span>
        </div>
        <el-form-item class="nav-form-item" label="真实姓名" prop="realName">
          <el-input
            maxlength="256"
            v-model="formData.realName"
            style="width: 300px"
            placeholder="请输入真实姓名"
          />
        </el-form-item>

        <el-form-item class="nav-form-item" label="手机号" prop="mobile">
          <el-input
            maxlength="256"
            v-model="formData.mobile"
            style="width: 300px"
            placeholder="请输入手机号"
          />
        </el-form-item>

        <el-form-item
          class="nav-form-item"
          label="登陆用户名"
          prop="loginAccount"
        >
          <el-input
            style="width: 300px"
            v-model="formData.loginAccount"
            placeholder="请输入登陆用户名"
          />
        </el-form-item>

        <el-form-item
          class="nav-form-item"
          label="分成比例%"
          prop="holderRatio"
        >
          <el-input
            type="number"
            style="width: 300px"
            v-model="formData.holderRatio"
            step="0.1"
            min="0.1"
            value="1"
            max="100"
            placeholder="请输入分成比例"
          />
        </el-form-item>

        <el-form-item class="nav-form-item" label="地址" prop="address">
          <el-input
            maxlength="256"
            v-model="formData.address"
            style="width: 300px"
            placeholder="请输入地址"
          />
        </el-form-item>

        <el-form-item class="nav-form-item" label="订购日期" prop="buyTime">
          <el-date-picker
            　　v-model="formData.buyTime"
            　　type="datetime"
            　　value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择分成者入驻日期"
            style="width:300px;"
            　　
          >
          </el-date-picker>
        </el-form-item>

        <div class="bottomTip">
          新增分成者初始密码: admin123456
        </div>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="confimHandler">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="确定重置密码？"
      :visible.sync="showResetPasswordDialog"
      width="25%"
    >
      <span style="margin: 20px 0;display: inline-block;"
        >密码将被重置为admin123456</span
      >
      <span slot="footer">
        <el-button @click="resetPasswordSubmit" type="primary">确定</el-button>
        <el-button type="primary" @click="showResetPasswordDialog = false"
          >取消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
// 引入table列数据
import { tableColumn } from "./tableConfig";
// 引入form设定
import { addFormItem, editFormItem } from "./formConfig";
import {
  accountChildrenList,
  accountDtail,
  accountCreate,
  accountUpdate,
  radioUpdate,
} from "@/api/accountChildren";
import { resetPassword } from "@/api/sysUser";
// 引入form组件
import Form from "@/components/form";
export default {
  components: {
    Form,
  },
  data() {
    return {
      tableData: [],
      tableColumn: tableColumn,
      activeFormItem: [],
      addFormItem: addFormItem,
      editFormItem: editFormItem,
      showDialog: false,
      dialogVisible: false,
      form: {
        pageNo: 1,
        pageSize: 10,
        searchKey: "",
      },
      dialogTitle: "",
      surplusRadio: 0,
      isAdd: false,
      tableTotal: 0,
      id: null,
      showResetPasswordDialog: false,
      shareholder: null,
      formData: {
        address: "",
        buyTime: "",
        holderRatio: "",
        loginAccount: "",
        mobile: "",
        realName: "",
      },
    };
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    confimHandler() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            this.add();
          } else {
            this.edit();
          }
        }
      });
    },

    onClick() {
      if (this.isAdd) {
        accountCreate(this.$refs.getForm.form).then((res) => {
          if (res.message.code === 0) {
            this.$message({
              message: "新增分成者成功",
              type: "success",
            });
            this.getTableData();
            this.getAccountDtail();
            this.showDialog = false;
          } else {
            this.$message({
              message: res.data,
              type: "fail",
            });
          }
        });
      } else {
        radioUpdate(this.$refs.getForm.form).then((res) => {
          if (res.message.code === 0) {
            this.$message({
              message: "编辑成功",
              type: "success",
            });
            this.getTableData();
            this.getAccountDtail();
            this.showDialog = false;
          } else {
            this.$message({
              message: res.message.message,
              type: "fail",
            });
          }
        });
      }
    },
    onSubmit() {
      this.getTableData();
    },

    addHandler() {
      this.getAccountDtail();
      this.isAdd = true;
      this.formData.address = "";
      this.formData.buyTime = "";
      this.formData.holderRatio = "";
      this.formData.loginAccount = "";
      this.formData.mobile = "";
      this.formData.realName = "";
      this.dialogVisible = true;
    },

    add() {
      accountCreate(this.formData).then((res) => {
        if (res.message.code == 0) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.dialogVisible = false;
          this.getTableData();
          this.getAccountDtail();
        } else {
          this.$message.error(res.message);
        }
      });
    },

    editCardHandler(row) {
      this.getAccountDtail();
      this.isAdd = false;

      this.formData.id = row.id;
      this.formData.address = row.address;
      this.formData.buyTime = row.buyTime;
      this.formData.holderRatio = row.holderRatio;
      this.formData.loginAccount = row.loginAccount;
      this.formData.mobile = row.mobile;
      this.formData.realName = row.realName;
      this.dialogVisible = true;
    },

    edit() {
      var params = this.formData;
      params.showPage = null;
      params.showPageList = null;
      radioUpdate(params).then((res) => {
        if (res.message.code == -1) {
          this.$message.error(res.data);
        } else {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.dialogVisible = false;
          this.getTableData();
          this.getAccountDtail();
        }
      });
    },

    resetPassword(userId) {
      this.showResetPasswordDialog = true;
      this.shareholder = userId;
    },
    resetPasswordSubmit() {
      resetPassword({ userId: this.shareholder }).then((res) => {
        if (res.message.code === 0) {
          this.$message({
            message: "密码重置成功",
            type: "success",
          });
        } else {
          this.$message({
            message: res.message.message,
            type: "fail",
          });
        }
      });
      this.showResetPasswordDialog = false;
    },
    getAccountDtail() {
      accountDtail(this.$store.state.user.userId).then((res) => {
        // console.log(res);
        this.surplusRadio = res.data.surplusRadio;
        if (this.isAdd) {
          this.addFormItem[3].max = this.surplusRadio;
        } else {
          this.editFormItem[3].max = this.surplusRadio;
        }
      });
    },
    getTableData() {
      console.log("获取表单数据");
      accountChildrenList(this.form).then((res) => {
        this.tableData = res.data.list;
        this.tableTotal = res.data.total;
        console.log(this.tableData);
      });
    },
    handleCurrentChange(pageNo) {
      this.form.pageNo = pageNo;
      this.getTableData();
    },
    holderStatusChange(row) {
      var messageText = "";
      if (row.holderStatus == 1) {
        messageText = "打开成功";
      } else {
        messageText = "关闭成功";
      }
      accountUpdate({
        holderStatus: row.holderStatus,
        id: row.id,
      }).then((res) => {
        if (res.message.code == 0) {
          this.$message({
            message: messageText,
            type: "success",
          });
        } else {
          this.$message({
            message: "设置失败，请联系管理员",
            type: "success",
          });
        }
        this.getTableData();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.body {
  padding: 20px;
}
::v-deep .el-input__suffix {
  right: 0;
}
::v-deep .el-input {
  width: 280px;
}

::v-deep .el-table__fixed-right::before {
  background-color: transparent;
}

::v-deep .el-pagination {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.topTip,
.bottomTip {
  margin: 15px 0;
}

.topTip {
  color: rgb(255, 73, 73);
  font-size: 16px;
}

::v-deep .el-dialog__body {
  padding: 0 20px;
  // color: #617d97;
}
</style>
