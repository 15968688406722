// 活动状态
export const ACTIVITY_STATUS = Object.freeze([
  { label: '草稿', value: -1, color: '#f59a23' },
  { label: '未发行', value: 0, color: '#1890ff' },
  { label: '已发行', value: 1, color: '#1890ff' },
  { label: '已结束', value: 2, color: '#aaaaaa' },
  { label: '手动停止', value: 3, color: '#aaaaaa' },
]);

// 移动端显示状态
export const DISPLAY_STATUS = Object.freeze([
  { label: '显示', value: true },
  { label: '隐藏', value: false },
]);

export const MEMBER_STATUS = Object.freeze([
  {
    value: 0,
    label: '失效',
    color: '#aaaaaa',
  },
  {
    value: 1,
    label: '有效',
    color: '#1890ff',
  },
  {
    value: 2,
    label: '手动失效',
    color: '#aaaaaa',
  },
]);

export const PORT_STATUS = Object.freeze([
  {
    value: 0,
    label: '离线',
  },
  {
    value: 1,
    label: '故障',
  },
  {
    value: 2,
    label: '空闲',
  },
  {
    value: 3,
    label: '充电',
  },
  {
    value: 4,
    label: '插枪',
  },
]);

// 枪口状态(0:离线 1:故障 2:空闲 3:充电)
export const CONNECTOR_STATUS = Object.freeze([
  {
    value: 0,
    label: '离线',
    bgColor: 'rgba(29, 33, 41, 0.06)',
    color: 'rgba(78, 89, 105, 1)',
  },
  {
    value: 1,
    label: '故障',
    bgColor: 'rgba(223, 55, 55, 0.06)',
    color: '#DF3737',
  },
  {
    value: 2,
    label: '空闲',
    bgColor: 'rgba(8, 171, 103, 0.06)',
    color: 'rgba(8, 171, 103, 1)',
  },
  {
    value: 3,
    label: '充电中',
    bgColor: 'rgba(24, 173, 208, 0.06)',
    color: 'rgba(24, 173, 208, 1)',
  },
  {
    value: 4,
    label: '插枪',
    bgColor: 'rgba(247, 167, 13, 0.06)',
    color: 'rgba(247, 167, 13, 1)',
  },
]);

export const COUPON_STATUS = Object.freeze([
  {
    value: 0,
    label: '未使用',
  },
  {
    value: 1,
    label: '已使用',
  },
  {
    value: 2,
    label: '已过期',
  },
  {
    value: 3,
    label: '冻结中',
  },
]);

export const CHARGE_STATUS = Object.freeze([
  {
    value: '9001',
    label: '未开始',
    type: 'warning',
  },
  {
    value: '9002',
    label: '充电中',
    type: '',
  },
  {
    value: '9003',
    label: '充电完成',
    type: 'success',
  },
]);

export const ORDERSTATE_STATUS = Object.freeze([
  {
    value: 1,
    label: '下单',
    type: 'info',
  },
  {
    value: 2,
    label: '取消',
    type: 'cancel',
  },
  {
    value: 3,
    label: '支付成功',
    type: 'success',
  },
  {
    value: 4,
    label: '退款',
    type: 'danger',
  },
  {
    value: 5,
    label: '完成',
    type: 'success',
  },
  {
    value: 6,
    label: '结算中',
    type: 'warning',
  },
  {
    value: 7,
    label: '手动结算',
    type: 'warning',
  },
  {
    value: 8,
    label: '待支付',
    type: 'danger',
  },
  {
    value: 9,
    label: '部分已支付',
    type: 'danger ',
  },
  {
    value: 10,
    label: '调控失败取消',
    type: 'danger ',
  },
]);

export const PAY_WAY = Object.freeze([
  {
    value: 1,
    label: '账户余额',
  },
  {
    value: 2,
    label: '微信支付分',
  },
  // {
  //   value: 3,
  //   label: "芝麻信用分",
  // },
]);

export const CLASSIFY = Object.freeze([
  {
    value: 0,
    label: '尖期',
    color: '#F87700',
    bgColor: '#F877000F',
  },
  {
    value: 1,
    label: '峰期',
    color: '#F7A70D',
    bgColor: '#F7A70D0F',
  },
  {
    value: 2,
    label: '平期',
    color: '#08AB67',
    bgColor: '#08AB670F',
  },
  {
    value: 3,
    label: '谷期',
    color: '#4E5969',
    bgColor: '#4E59690F',
  },
  {
    value: 4,
    label: '深谷期',
    color: '#DF3737',
    bgColor: '#DF37370F',
  },
]);

// 建设场所
export const CONSTRUCTION_SITE = Object.freeze([
  {
    value: 1,
    label: '写字楼',
  },
  {
    value: 2,
    label: '居民区',
  },
  {
    value: 3,
    label: '工业园区',
  },
  {
    value: 4,
    label: '公共机构',
  },
  {
    value: 99,
    label: '其他',
  },
]);

// 补付类型(1:卡 2:账户 3:微信 4:支付宝)
export const APPEND_TYPE = Object.freeze([
  {
    value: 1,
    label: '卡',
  },
  {
    value: 2,
    label: '账户余额',
  },
  {
    value: 3,
    label: '微信',
  },
  {
    value: 4,
    label: '支付宝',
  },
]);

//是否异常(1:是 0:否)
export const IS_ABNORMAL = Object.freeze([
  {
    value: true,
    label: '是',
  },
  {
    value: false,
    label: '否',
  },
]);

// 是否(0:否 1:是)
export const IS = Object.freeze([
  {
    value: 0,
    label: '否',
  },
  {
    value: 1,
    label: '是',
  },
]);

// 启动模式(1:扫码启动 2:VIN码启动)
export const START_MODE = Object.freeze([
  {
    value: 1,
    label: '扫码启动',
  },
  {
    value: 2,
    label: 'VIN码启动',
  },
]);

// 节点类型(1:电站 2:电表 3:电桩 4:充电枪)
export const NODE_TYPE = Object.freeze([
  {
    value: 1,
    label: '电站',
    color: '#F87700',
    bgColor: 'rgba(248, 119, 0, 0.06)',
  },
  {
    value: 2,
    label: '电表',
    color: '#18ADD0',
    bgColor: 'rgba(24, 173, 208, 0.06)',
  },
  {
    value: 3,
    label: '充电桩',
    color: '#0DC8AC',
    bgColor: 'rgba(13, 200, 172, 0.06)',
  },
  {
    value: 4,
    label: '充电枪',
    color: '#3B92EF',
    bgColor: 'rgba(59, 146, 239, 0.06)',
  },
]);

// 	设备状态(0:离线 1:故障 2:在线)
export const DEVICE_STATUS = Object.freeze([
  {
    value: 0,
    label: '离线',
    type: 'info',
  },
  {
    value: 1,
    label: '故障',
    type: 'danger',
  },
  {
    value: 2,
    label: '在线',
    type: 'success',
  },
]);

// 调度策略(0:关闭 1:时间 2:均分 3:智能)
export const DISPATCHING_STRATEGY = Object.freeze([
  {
    value: 0,
    label: '关闭',
  },
  {
    value: 1,
    label: '时间顺序',
  },
  {
    value: 2,
    label: '均分',
  },
  {
    value: 3,
    label: '智能调控',
  },
]);

// 电桩类型(0:慢充/交流 1:快充/直流)
export const CHARGE_TYPE = Object.freeze([
  {
    value: 0,
    label: '交流',
  },
  {
    value: 1,
    label: '直流',
  },
]);

// 	类型(-1:应付订单金额 0:待支付金额 1：已支付 11：补支付 2:发起退款 3:退款到账 4:退款失败
export const PAY_FLOW_TYPE = Object.freeze([
  {
    value: -1,
    label: '应付金额',
  },
  {
    value: 0,
    label: '待支付',
  },
  {
    value: 1,
    label: '已支付',
  },
  {
    value: 11,
    label: '补支付',
  },
  {
    value: 2,
    label: '发起订单退款',
  },
  {
    value: 3,
    label: '订单退款',
  },
  {
    value: 4,
    label: '退款失败',
  },
  {
    value: 5,
    label: '退款中',
  },
]);

// 	电枪状态(0:离线 1:故障 2:空闲 3:充电 4:占用)
export const CONNECTOR_STAT = Object.freeze([
  {
    value: 0,
    label: '离线',
    color: 'rgba(223, 55, 55, 1)',
    progressColor: 'rgba(223, 55, 55, 0.25)',
    textBgColor: 'rgba(223, 55, 55, 0.08)',
    bgColor: 'rgba(223, 55, 55, 0.04)',
  },
  {
    value: 1,
    label: '故障',
    color: 'rgba(223, 55, 55, 1)',
    progressColor: 'rgba(223, 55, 55, 0.25)',
    textBgColor: 'rgba(223, 55, 55, 0.08)',
    bgColor: 'rgba(223, 55, 55, 0.04)',
  },
  {
    value: 2,
    label: '空闲',
    type: 'success',
    color: 'rgba(8, 171, 103, 1)',
    progressColor: 'rgba(8, 171, 103, 0.25)',
    textBgColor: 'rgba(8, 171, 103, 0.08)',
    bgColor: 'rgba(8, 171, 103, 0.04)',
  },
  {
    value: 3,
    label: '充电',
    color: '#057C98',
    progressColor: 'rgba(5, 124, 152, 0.25)',
    textBgColor: 'rgba(5, 124, 152, 0.08)',
    bgColor: 'rgba(5, 124, 152, 0.04)',
  },
  {
    value: 4,
    label: '占用',
    color: 'rgba(248, 119, 0, 1)',
    progressColor: 'rgba(248, 119, 0, 0.25)',
    textBgColor: 'rgba(248, 119, 0, 0.08)',
    bgColor: 'rgba(248, 119, 0, 0.04)',
  },
  {
    value: 999,
    label: '停用',
    color: 'rgba(78, 89, 105, 1)',
    progressColor: 'rgba(78, 89, 105, 0.25)',
    textBgColor: 'rgba(78, 89, 105, 0.08)',
    bgColor: 'rgba(78, 89, 105, 0.04)',
  },
]);

// 电桩状态(0:在线 1:离线 2:停用)
export const CHARGE_PLOT_STAT = Object.freeze([
  {
    value: 0,
    label: '在线',
    color: 'rgba(8, 171, 103, 1)',
  },
  {
    value: 1,
    label: '离线',
    color: 'rgba(223, 55, 55, 1)',
  },
  {
    value: 2,
    label: '停用',
    color: 'rgba(78, 89, 105, 1)',
  },
]);

// connectorReturn 电枪是否归位(0:否 1:是 2:未知)
export const CONNECTOR_RETURN = Object.freeze([
  {
    value: 0,
    label: '否',
  },
  {
    value: 1,
    label: '是',
  },
  {
    value: 2,
    label: '未知',
  },
]);

// 	电池类型(1:铅酸电池 2:氢电池 3:磷酸铁锂电池 4:锰酸锂电池 5:钴酸锂电池 6:三元材料电池 7:聚合物锂离子电池 8:钛酸锂电池 255:其他)
export const BATTERY_TYPE = Object.freeze([
  {
    value: 1,
    label: '铅酸电池',
  },
  {
    value: 2,
    label: '氢电池',
  },
  {
    value: 3,
    label: '磷酸铁锂电池',
  },
  {
    value: 4,
    label: '锰酸锂电池',
  },
  {
    value: 5,
    label: '钴酸锂电池',
  },
  {
    value: 6,
    label: '三元材料电池',
  },
  {
    value: 7,
    label: '聚合物锂离子电池',
  },
  {
    value: 8,
    label: '钛酸锂电池',
  },
  {
    value: 255,
    label: '其他',
  },
]);

// 	充电电流实时(0:正常 1:过流 2:不可信)
export const CHARGE_CURRENT_REALTIME = Object.freeze([
  {
    value: 0,
    label: '正常',
    color: 'rgba(29, 33, 41, 1)',
  },
  {
    value: 1,
    label: '过流',
    color: 'rgba(223, 55, 55, 1)',
  },
  {
    value: 2,
    label: '不可信',
    color: 'rgba(247, 167, 13, 1)',
  },
]);

// 电池组产权标识(0:租赁 1:车自有)
export const BATTERY_GROUP_OWNER = Object.freeze([
  {
    value: 0,
    label: '租赁',
  },
  {
    value: 1,
    label: '车自有',
  },
]);

// 电池电压实时状态(0:正常 1:过高 2:过低)
export const BATTERY_VOLTAGE_REALTIME = Object.freeze([
  {
    value: 0,
    label: '正常',
    color: 'rgba(29, 33, 41, 1)',
  },
  {
    value: 1,
    label: '过高',
    color: 'rgba(223, 55, 55, 1)',
  },
  {
    value: 2,
    label: '过低',
    color: 'rgba(247, 167, 13, 1)',
  },
]);

// 告警级别(1:紧急 2:严重 3:一般)
export const ALARM_LEVEL = Object.freeze([
  {
    value: 1,
    label: '紧急',
    color: 'rgba(223, 55, 55, 1)',
    bgColor: 'rgba(223, 55, 55, 0.06)',
    type: 'error',
  },
  {
    value: 2,
    label: '严重',
    color: 'rgba(248, 119, 0, 1)',
    bgColor: 'rgba(248, 119, 0, 0.06)',
    type: 'serious',
  },
  {
    value: 3,
    label: '一般',
  },
]);

// 设备类型(1:充电桩 2:充电枪 3:电表 4:其他)
export const DEVICE_TYPE = Object.freeze([
  {
    value: 1,
    label: '充电桩',
  },
  {
    value: 2,
    label: '充电枪',
  },
  {
    value: 3,
    label: '电表',
  },
  {
    value: 4,
    label: '其他',
  },
]);

// 告警类型|告警来源 (S:设备告警 P:系统告警)
export const ALARM_TYPE = Object.freeze([
  {
    value: 'S',
    label: '设备告警',
  },
  {
    value: 'P',
    label: '系统告警',
  },
]);
// 处理结果(0:未处理 1:已处理 2:挂起)
export const ALARM_RESULT = Object.freeze([
  {
    value: 0,
    label: '未处理',
  },
  {
    value: 1,
    label: '已处理',
  },
  {
    value: 2,
    label: '挂起',
  },
]);

export function findLabelByValue(dic, value, key = 'label', val = 'value') {
  const status = dic.find((item) => item[val] === value);
  return status ? status[key] : value;
}

export function findValue(dic, value, val = 'value') {
  const status = dic.find((item) => item[val] === value);
  return status;
}
