<template>
  <el-dialog
    title="编辑"
    :visible.sync="visible"
    width="550px"
    :close-on-click-modal="false"
    :before-close="handleBeforeClose"
  >
    <div>
      <div
        class="error-box"
        :style="{ backgroundColor: alarmBgColor(currentRow.level) }"
      >
        <div class="error-header">
          <img
            :src="alarmIcon(currentRow.level)"
            class="error-icon"
            mode="scaleToFill"
          />
          <div class="error-label-container">
            <div class="error-label">
              <div class="error-title">{{ currentRow.name || '-' }}</div>
            </div>
            <div class="info-suffix">
              <div class="info-item">
                <div class="info-label">设备类型</div>
                <div class="info-value">
                  {{ deviceTypeFormatter(currentRow.deviceType) }}
                </div>
              </div>
              <div class="info-item">
                <div class="info-label">告警码</div>
                <div class="info-value">{{ currentRow.code || '-' }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-box">
        <el-form
          ref="submitFormRef"
          :model="submitForm"
          label-width="80px"
          style="width: 100%;"
          label-position="top"
          size="mini"
          :rules="rules"
        >
          <el-form-item label="告警等级" prop="level">
            <el-radio-group v-model="submitForm.level">
              <el-radio
                v-for="item in ALARM_LEVEL"
                :key="item.value"
                :label="item.value"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
          </el-form-item>

          <el-form-item label="告警说明" prop="description">
            <el-input
              type="textarea"
              v-model="submitForm.description"
              placeholder="请输入 "
              maxlength="100"
              :rows="3"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-form>
      </div>

      <span class="drawer-footer">
        <div class="drawer-footer-inner">
          <el-button
            type=""
            @click="
              handleBeforeClose(() => {
                visible = false;
              })
            "
            >取消</el-button
          >
          <el-button type="primary" @click="debouncedHandleButtonClick"
            >确定</el-button
          >
        </div>
      </span>
    </div>
  </el-dialog>
</template>

<script setup>
import { ref } from 'vue';
import { DEVICE_TYPE, ALARM_LEVEL, findLabelByValue } from '@/utils/const';
import { Message } from 'element-ui';
import { debounce } from 'lodash-es';
import { modifySetting } from '@/api/alert';

let prop = defineProps({
  title: {
    type: String,
    default: '告警详情',
  },
});

let iconOption = {
  1: require('@/assets/alarm/warning-error.png'),
  2: require('@/assets/alarm/warning-warning.png'),
  3: require('@/assets/alarm/warning-normal.png'),
};
let bgColorOption = {
  1: 'rgba(223, 55, 55, 0.08)',
  2: 'rgba(248, 119, 0, 0.08)',
  3: 'rgba(5, 124, 152, 0.08)',
};
function alarmIcon(val) {
  return iconOption[val] || require('@/assets/alarm/warning-normal.png');
}
function alarmBgColor(val) {
  return bgColorOption[val] || 'rgba(5, 124, 152, 0.08)';
}
function deviceTypeFormatter(val) {
  return findLabelByValue(DEVICE_TYPE, val);
}

const visible = ref(false);

const currentRow = ref({});
function open(row) {
  currentRow.value = row;
  submitForm.value = {
    level: currentRow.value.level,
    description: currentRow.value.description,
  };
  visible.value = true;
}
function close() {
  visible.value = false;
}
defineExpose({ open, close });
const emit = defineEmits(['change']);

function handleBeforeClose(done) {
  submitFormRef.value.resetFields();
  done();
}

// 表单
const submitForm = ref({
  description: '',
  level: 1,
});

const rules = {
  level: [
    {
      required: true,
      message: '请选择告警等级',
      trigger: 'change',
    },
  ],
};

const submitFormRef = ref();
function handleConfirm() {
  submitFormRef.value.validate((valid) => {
    if (valid) {
      modifySetting({
        id: currentRow.value.id,
        ...submitForm.value,
      })
        .then((res) => {
          if (res.message.code == 0) {
            visible.value = false;
            submitFormRef.value.resetFields();
            currentRow.value = {};
            emit('change');
            Message.success('操作成功');
          } else {
            Message.error(res.message.message);
          }
        })
        .catch((e) => {
          console.log('🚀 ~ file: handleDialog.vue:208 ~ e:', e);
        });
    }
  });
}

const debouncedHandleButtonClick = debounce(
  () => {
    handleConfirm();
  },
  800,
  {
    leading: true,
    trailing: false,
  }
);
</script>

<style lang="scss" scoped>
.error-box {
  display: flex;
  padding: 24px 24px 32px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-top: 1px solid #e5e6eb;
  background: rgba(5, 124, 152, 0.08);
  .error-header {
    display: flex;
    align-items: center;
    gap: 12px;
    .error-label-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 4px;
      flex: 1 0 0;
    }
    .error-icon {
      width: 54px;
      height: 54px;
      flex-shrink: 0;
    }
    .error-label {
      display: flex;
      align-items: center;
      gap: 4px;
      .error-title {
        max-width: 350px;
        color: var(--PC-, #1d2129);
        font-family: 'PingFang SC';
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    .info-suffix {
      display: flex;
      align-items: center;
      gap: 24px;
      align-self: stretch;
      .info-item {
        display: flex;
        align-items: center;
        gap: 12px;
      }
      .info-label {
        color: var(--PC-, #86909c);
        font-family: 'PingFang SC';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .info-value {
        color: var(--PC-, #1d2129);
        font-family: 'PingFang SC';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}
.drawer-footer {
  display: flex;
  height: 53px;
  border-top: 1px solid var(--PC--, #e5e6eb);
  padding: 10px 0;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  align-self: stretch;
  .drawer-footer-inner {
    display: flex;
    padding: 0px 24px;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    align-self: stretch;
  }
}
.form-box {
  display: flex;
  padding: 24px 24px 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  position: relative;
  z-index: 61;
  top: -16px;
  background: #fff;
  border-radius: 8px;
}
::v-deep .el-dialog {
  border-radius: 8px;
  background: var(--PC-, #fff);
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: var(--PC--, #1d2129);
}
::v-deep .el-dialog__title {
  color: var(--PC-, #1d2129);
  font-family: 'PingFang SC';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
::v-deep .el-dialog__header {
  box-shadow: 0px 1px 0px 0px var(--PC--, #e5e6eb);
  padding: 16px 24px 8px 24px;
}
::v-deep .el-dialog__body {
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 16px;
}
::v-deep .el-form-item__label {
  color: var(--PC-, #1d2129);
}
.el-radio {
  color: var(--PC-, #1d2129);
}
::v-deep .el-form--label-top .el-form-item__label {
  padding-bottom: 0;
}
</style>
