var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "alarm-management-detail" },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _setup.handleClick },
          model: {
            value: _setup.activeName,
            callback: function ($$v) {
              _setup.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c("el-tab-pane", { attrs: { label: "设备上报", name: "S" } }),
          _vm._v(" "),
          _c("el-tab-pane", { attrs: { label: "平台告警", name: "P" } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "main" },
        [
          _c(_setup.MainSearchBar, {
            attrs: { isExpendShow: false },
            on: { search: _setup.searchData, reset: _setup.resetSearch },
            scopedSlots: _vm._u([
              {
                key: "form",
                fn: function () {
                  return [
                    _c(
                      "el-form",
                      {
                        ref: "formRef",
                        staticClass: "search-form-layout",
                        attrs: {
                          model: _setup.searchForm,
                          "label-width": "auto",
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "告警名称", prop: "name" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入", maxlength: "50" },
                              model: {
                                value: _setup.searchForm.name,
                                callback: function ($$v) {
                                  _vm.$set(_setup.searchForm, "name", $$v)
                                },
                                expression: "searchForm.name",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "告警码", prop: "code" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入", maxlength: "50" },
                              model: {
                                value: _setup.searchForm.code,
                                callback: function ($$v) {
                                  _vm.$set(_setup.searchForm, "code", $$v)
                                },
                                expression: "searchForm.code",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "设备类型", prop: "deviceType" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { clearable: "", placeholder: "请选择" },
                                model: {
                                  value: _setup.searchForm.deviceType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _setup.searchForm,
                                      "deviceType",
                                      $$v
                                    )
                                  },
                                  expression: "searchForm.deviceType",
                                },
                              },
                              _vm._l(_setup.DEVICE_TYPE, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "content-box" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _setup.tableLoading,
                      expression: "tableLoading",
                    },
                  ],
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    "row-key": "id",
                    data: _setup.tableData,
                    "header-cell-style": {
                      background: "#f7f8fa",
                      color: "#000000",
                      fontSize: "14px",
                    },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      label: "序号",
                      align: "left",
                      width: "60",
                      fixed: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "name",
                      label: "告警名称",
                      align: _setup.tableAlign,
                      "min-width": "180",
                      fixed: "",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "deviceType",
                      label: "设备类型",
                      align: _setup.tableAlign,
                      "min-width": "120",
                      fixed: "",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _setup.deviceTypeFormatter(row.deviceType)
                                ) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "level",
                      label: "告警等级",
                      align: _setup.tableAlign,
                      "min-width": "120",
                      fixed: "",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c(_setup.PlainTag, {
                              attrs: {
                                color: _setup.alarmLevelColorFormatter(
                                  row.level
                                ),
                                bgColor: _setup.alarmLevelBgColorFormatter(
                                  row.level
                                ),
                                text: _setup.alarmLevelFormatter(row.level),
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "code",
                      label: "告警码",
                      align: _setup.tableAlign,
                      "min-width": "120",
                      fixed: "",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "description",
                      label: "告警说明",
                      align: _setup.tableAlign,
                      "min-width": "120",
                      fixed: "",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.description || "-") +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      align: _setup.tableAlign,
                      width: "120",
                      fixed: "right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              { staticClass: "editCell" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _setup.handleEdit(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(_setup.Pagination, {
                staticStyle: { "margin-top": "10px" },
                attrs: {
                  background: false,
                  total: _setup.pageParams.total,
                  page: _setup.pageParams.pageNum,
                  limit: _setup.pageParams.pageSize,
                  layout: "total, -> ,prev, pager, next, sizes, jumper",
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_setup.pageParams, "pageNum", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_setup.pageParams, "pageSize", $event)
                  },
                  pagination: _setup.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(_setup.settingEditDialog, {
        ref: "settingEditDialogRef",
        on: { change: _setup.handleEditDialogChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }