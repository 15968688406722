<template>
  <el-dialog
    title="批量处理"
    :visible.sync="visible"
    width="700px"
    :close-on-click-modal="false"
    :before-close="handleBeforeClose"
  >
    <div>
      <div class="table-box">
        <el-table :data="currentRow" border style="width: 100%">
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column
            prop="alertName"
            label="告警名称"
            min-width="180"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="level"
            label="告警等级"
            min-width="100"
            show-overflow-tooltip
          >
            <template slot-scope="{ row }">
              <PlainTag
                :color="alarmLevelColorFormatter(row.level)"
                :bgColor="alarmLevelBgColorFormatter(row.level)"
                :text="alarmLevelFormatter(row.level)"
              ></PlainTag>
            </template>
          </el-table-column>
          <el-table-column
            prop="plotName"
            label="充电站名称"
            min-width="180"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="isRestored"
            label="是否已恢复"
            min-width="100"
            show-overflow-tooltip
          >
            <template slot-scope="{ row }">
              <span
                :style="{
                  color: row.isRestored ? '#1D2129' : '#DF3737',
                }"
              >
                {{ row.isRestored ? '是' : '否' }}
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="form-box">
        <el-form
          ref="submitFormRef"
          :model="submitForm"
          label-width="80px"
          style="width: 100%;"
          label-position="top"
          size="mini"
          :rules="rules"
        >
          <el-form-item label="处理结果" prop="processResult">
            <el-radio-group v-model="submitForm.processResult">
              <el-radio label="1">已处理</el-radio>
              <el-radio label="2">挂起</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="恢复原因" prop="restoredReason">
            <el-radio-group v-model="submitForm.restoredReason">
              <el-radio label="设备已恢复但未上传恢复"></el-radio>
              <el-radio label="无需处理可忽略"></el-radio>
              <el-radio label="人工处理"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="处理说明" prop="operatorDetail">
            <el-input
              type="textarea"
              v-model="submitForm.operatorDetail"
              placeholder="请输入 "
              maxlength="150"
              :rows="3"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-form>
      </div>

      <span class="drawer-footer">
        <div class="drawer-footer-inner">
          <el-button
            type=""
            @click="
              handleBeforeClose(() => {
                visible = false;
              })
            "
            >取消</el-button
          >
          <el-button type="primary" @click="debouncedHandleButtonClick"
            >确定</el-button
          >
        </div>
      </span>
    </div>
  </el-dialog>
</template>

<script setup>
import { ref } from 'vue';
import { ALARM_LEVEL, findLabelByValue } from '@/utils/const';
import LightTag from '@/components/LightTag/index.vue';
import { alertHandle } from '@/api/alert';
import { Message } from 'element-ui';
import { debounce } from 'lodash-es';
import PlainTag from '@/components/PlainTag/index.vue';

let prop = defineProps({
  title: {
    type: String,
    default: '告警详情',
  },
});

function alarmLevelFormatter(val) {
  return findLabelByValue(ALARM_LEVEL, val);
}
function alarmLevelBgColorFormatter(val) {
  return findLabelByValue(ALARM_LEVEL, val, 'bgColor');
}
function alarmLevelColorFormatter(val) {
  return findLabelByValue(ALARM_LEVEL, val, 'color');
}
let iconOption = {
  1: require('@/assets/alarm/warning-error.png'),
  2: require('@/assets/alarm/warning-warning.png'),
  3: require('@/assets/alarm/warning-normal.png'),
};
let bgColorOption = {
  1: 'rgba(223, 55, 55, 0.08)',
  2: 'rgba(248, 119, 0, 0.08)',
  3: 'rgba(5, 124, 152, 0.08)',
};
const visible = ref(false);

const currentRow = ref({});
function open(row) {
  currentRow.value = row;
  visible.value = true;
}
function close() {
  visible.value = false;
}
defineExpose({ open, close });
const emit = defineEmits(['change']);

function handleBeforeClose(done) {
  submitFormRef.value.resetFields();
  done();
}

// 表单
const submitForm = ref({
  processResult: '1',
  restoredReason: '人工处理',
  operatorDetail: '',
});

const rules = {
  processResult: [
    {
      required: true,
      message: '请选择处理结果',
      trigger: 'change',
    },
  ],
  restoredReason: [
    {
      required: true,
      message: '请选择恢复原因',
      trigger: 'change',
    },
  ],
};

const submitFormRef = ref();
function handleConfirm() {
  submitFormRef.value.validate((valid) => {
    if (valid) {
      alertHandle({
        eventId: currentRow.value.map((item) => {
          return item.eventId;
        }),
        ...submitForm.value,
      })
        .then((res) => {
          if (res.message.code == 0) {
            visible.value = false;
            submitFormRef.value.resetFields();
            currentRow.value = {};
            emit('change');
            Message.success('操作成功');
          } else {
            Message.error(res.message.message);
          }
        })
        .catch((e) => {
          console.log('🚀 ~ file: handleDialog.vue:208 ~ e:', e);
        });
    }
  });
}

const debouncedHandleButtonClick = debounce(
  () => {
    handleConfirm();
  },
  800,
  {
    leading: true,
    trailing: false,
  }
);
</script>

<style lang="scss" scoped>
.table-box {
  display: flex;
  padding: 24px 24px 32px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.drawer-footer {
  display: flex;
  height: 53px;
  border-top: 1px solid var(--PC--, #e5e6eb);
  padding: 10px 0;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  align-self: stretch;
  .drawer-footer-inner {
    display: flex;
    padding: 0px 24px;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    align-self: stretch;
  }
}
.form-box {
  display: flex;
  padding: 24px 24px 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  position: relative;
  z-index: 61;
  top: -16px;
  background: #fff;
  border-radius: 8px;
}
::v-deep .el-dialog {
  border-radius: 8px;
  background: var(--PC-, #fff);
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: var(--PC--, #1d2129);
}
::v-deep .el-dialog__title {
  color: var(--PC-, #1d2129);
  font-family: 'PingFang SC';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
::v-deep .el-dialog__header {
  box-shadow: 0px 1px 0px 0px var(--PC--, #e5e6eb);
  padding: 16px 24px 8px 24px;
}
::v-deep .el-dialog__body {
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 16px;
}
::v-deep .el-form-item__label {
  color: var(--PC-, #1d2129);
}
.el-radio {
  color: var(--PC-, #1d2129);
}
::v-deep .el-form--label-top .el-form-item__label {
  padding-bottom: 0;
}
</style>
