<template>
  <el-drawer
      size="600"
      :visible.sync="visible"
      :direction="direction"
      :withHeader="false"
      :before-close="handleClose"
      close-on-press-escape
      :modal="false"
  >
    <div class="drawer-container">
      <div class="header">
        <div class="header-layout">
          <div class="header-title">{{ title }}</div>
          <i class="el-icon-close close-icon" @click="close"> </i>
        </div>
      </div>
      <div class="process-box" v-loading="loading">
        <div
            class="error-box"
            :style="{ backgroundColor: alarmBgColor(data.level) }"
        >
          <div class="error-header">
            <div class="error-label-container">
              <div class="error-label">
                <div class="error-title ellipsis" v-overflow-tooltip>
                  {{ data.alertName || '-' }}
                </div>
                <LightTag
                    v-if="data.level"
                    :type="alarmLevelTypeFormatter(data.level)"
                    :text="alarmLevelFormatter(data.level)"
                ></LightTag>
              </div>
              <div class="error-time">
                告警时间：{{ data.createTime || '-' }}
              </div>
            </div>
          </div>
          <div class="info-box">
            <div class="info-box-item">
              <div class="info-box-item-label">充电站名称</div>
              <div class="info-box-item-value ellipsis" v-overflow-tooltip>
                {{ data.plotName || '-' }}
              </div>
            </div>
            <div class="info-box-item">
              <div class="info-box-item-label">设备名称</div>
              <div class="info-box-item-value ellipsis" v-overflow-tooltip>
                {{ data.deviceName || '-' }}
              </div>
            </div>
            <div class="info-box-item">
              <div class="info-box-item-label">设备类型</div>
              <div class="info-box-item-value ellipsis" v-overflow-tooltip>
                {{ deviceTypeFormatter(data.deviceType) || '-' }}
              </div>
            </div>
            <div class="info-box-item">
              <div class="info-box-item-label">告警码</div>
              <div class="info-box-item-value ellipsis" v-overflow-tooltip>
                {{ data.code || '-' }}
              </div>
            </div>
            <div class="info-box-item">
              <div class="info-box-item-label">告警来源</div>
              <div class="info-box-item-value" v-overflow-tooltip>
                {{ alarmTypeFormatter(data.alertType) || '-' }}
              </div>
            </div>
            <div class="info-box-item">
              <div class="info-box-item-label">所属运营商</div>
              <div class="info-box-item-value" v-overflow-tooltip>
                {{ data.operatorName || '-' }}
              </div>
            </div>
          </div>
        </div>
        <div class="header-title" style="margin-left: 24px">处理进度</div>
        <div style="padding: 0 24px;flex:1;width: 100%;">
          <el-timeline :reverse="true">
            <el-timeline-item
                v-for="(activity, index) in data.operatorInfos || []"
                :key="index"
                :size="
                index === (data.operatorInfos || []).length - 1 ? 'large' : ''
              "
                :color="
                index === (data.operatorInfos || []).length - 1
                  ? 'rgba(5, 124, 152, 0.08)'
                  : 'transparent'
              "
            >
              <div class="timeline-box">
                <div class="timeline-header">
                  <div class="timeline-header-title">
                    {{ alarmResultFormatter(activity.processResult) }}
                  </div>
                </div>
                <div
                    class="timeline-content"
                    v-html="infoDetailFormatter(activity)"
                ></div>
              </div>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
      <span class="drawer-footer">
        <div class="drawer-footer-inner">
          <el-button type="" @click="visible = false">关闭</el-button>
        </div>
      </span>
    </div>
  </el-drawer>
</template>

<script setup>
import { ref, computed } from 'vue';
import {
  ALARM_LEVEL,
  DEVICE_TYPE,
  ALARM_TYPE,
  ALARM_RESULT,
  findLabelByValue,
} from '@/utils/const';
import LightTag from '@/components/LightTag/index.vue';

const prop = defineProps({
  title: {
    type: String,
    default: '告警详情',
  },
  loading: {
    type: Boolean,
    default: false,
  },
  data: {
    type: Object,
    default: () => {
      return {};
    },
  },
});
const visible = ref(false);
const direction = ref('rtl');
const handleClose = (done) => {
  done();
};

function open() {
  visible.value = true;
}

function close() {
  visible.value = false;
}

defineExpose({open, close});

function alarmLevelFormatter(val) {
  return findLabelByValue(ALARM_LEVEL, val);
}

function alarmLevelTypeFormatter(val) {
  return findLabelByValue(ALARM_LEVEL, val, 'type');
}

function deviceTypeFormatter(val) {
  return findLabelByValue(DEVICE_TYPE, val);
}

function alarmTypeFormatter(val) {
  return findLabelByValue(ALARM_TYPE, val);
}

function alarmResultFormatter(val) {
  return findLabelByValue(ALARM_RESULT, val);
}

let iconOption = {
  1: require('@/assets/alarm/warning-error.png'),
  2: require('@/assets/alarm/warning-warning.png'),
  3: require('@/assets/alarm/warning-normal.png'),
};
let bgColorOption = {
  1: 'rgba(223, 55, 55, 0.08)',
  2: 'rgba(248, 119, 0, 0.08)',
  3: 'rgba(5, 124, 152, 0.08)',
};

function alarmBgColor(val) {
  return bgColorOption[val] || 'rgba(5, 124, 152, 0.08)';
}

// 	处理结果(0:未处理 1:已处理 2:挂起)
const infoDetailFormatter = (row) => {
  let str = '';
  switch (row.processResult) {
    case 0:
      str += `持续时间：${row.duration || '-'}`;
      break;
    case 1:
      str += `恢复时间：${row.operatorTime || '-'}\n`;
      str += `恢复原因：${row.restoredReason || '-'}\n`;
      str += `处理说明：${row.operatorDetail || '-'}`;
      break;
    case 2:
      str += `挂起时间：${row.operatorTime || '-'}\n`;
      str += `持续时间：${row.duration || '-'}\n`;
      str += `挂起说明：${row.operatorDetail || '-'}`;
      break;
  }
  return str;
};
</script>

<style lang="scss" scoped>
::v-deep .el-drawer {
  border-radius: 8px 0px 0px 8px;
}

.drawer-container {
  height: 100%;
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;

  .header {
    display: flex;
    padding: 16px 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-bottom: 1px solid #e5e6eb;

    .header-layout {
      display: flex;
      padding: 0px 24px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
    }

    .close-icon {
      color: var(--PC-, #1d2129);
      font-size: 16px;
    }
  }

  .error-box {
    display: flex;
    padding: 24px 24px 32px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    background: rgba(5, 124, 152, 0.08);

    .error-header {
      display: flex;
      align-items: center;
      gap: 12px;

      .error-label-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 4px;
        flex: 1 0 0;
      }

      .error-icon {
        width: 54px;
        height: 54px;
        flex-shrink: 0;
      }

      .error-label {
        display: flex;
        align-items: center;
        gap: 4px;

        .error-title {
          max-width: 350px;
          color: var(--PC-, #1d2129);
          font-family: 'PingFang SC';
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }

      .error-time {
        color: var(--PC-, #4e5969);
        font-family: 'PingFang SC';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .info-box {
    display: flex;
    padding: 12px;
    align-items: flex-start;
    align-content: flex-start;
    gap: 8px 32px;
    align-self: stretch;
    flex-wrap: wrap;
    border-radius: 8px;
    border: 2px solid #fff;
    background: rgba(255, 255, 255, 0.65);

    .info-box-item {
      display: flex;
      min-width: 200px;
      align-items: center;
      gap: 12px;
      flex: 1 0 0;
    }

    .info-box-item-label {
      color: var(--PC-, #86909c);
      font-family: 'PingFang SC';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .info-box-item-value {
      overflow: hidden;
      color: var(--PC-, #1d2129);
      text-overflow: ellipsis;
      font-family: 'PingFang SC';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      flex: 1;
    }
  }

  .process-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    flex: 1;
    overflow: auto;
  }
}

.drawer-footer {
  display: flex;
  height: 53px;
  border-top: 1px solid var(--PC--, #e5e6eb);
  padding: 10px 0;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  align-self: stretch;

  .drawer-footer-inner {
    display: flex;
    padding: 0px 24px;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    align-self: stretch;
  }
}

.header-title {
  color: var(--PC-, #1d2129);
  font-family: 'PingFang SC';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.timeline-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  .timeline-header {
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;

    .timeline-header-title {
      color: var(--PC-, #1d2129);
      font-family: 'PingFang SC';
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .timeline-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    white-space: pre;
    line-height: 23px;
    color: var(--PC-, #86909c);
    font-family: 'PingFang SC';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
}

::v-deep .el-timeline-item__tail {
  position: absolute;
  left: 7px;
  top: 16%;
  height: 75%;
  border-left: 1px solid #e4e7ed;
}

::v-deep .el-timeline-item__node::before {
  content: '';
  width: var(--BorderRadius-borderRadius, 6px);
  height: var(--BorderRadius-borderRadius, 6px);
  flex-shrink: 0;
  background: #c9cdd4;
  border-radius: 50%;
}

::v-deep .el-timeline-item__node-- {
  content: '';
  flex-shrink: 0;
  background: transparent;
  width: 14px;
  height: 14px;
  left: 0;
  top: -2px;
  border-radius: 50%;
}

::v-deep .el-timeline-item__node--large::before {
  content: '';
  width: var(--BorderRadius-borderRadius, 6px);
  height: var(--BorderRadius-borderRadius, 6px);
  flex-shrink: 0;
  background: var(--current-color);
  border-radius: 50%;
}

::v-deep .el-timeline {
  width: 100%;
  padding-top: 2px;
}

</style>
