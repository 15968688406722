export const tableColumn = [
    {
        label: "登录名",
        prop: "loginAccount"
    },
    {
        label: "真实姓名",
        prop: "realName"
    },
    {
        label: "手机号",
        prop: "mobile"
    },
    {
        label: "金额",
        prop: "amount"
    },
    {
        label: "分成比例(%)",
        prop: "holderRatio"
    },
    {
        label: "地址",
        prop: "address",
        minWidth:'150'
    },
    {
        label: "订购日期",
        prop: "buyDate"
    }
]
