function insertDotInLicensePlate(licensePlate) {
  if (typeof licensePlate !== "string" || !/^.{7,8}$/.test(licensePlate)) {
    throw new Error(
      "Invalid license plate format. It should be a string containing exactly 7 digits."
    );
  }

  return licensePlate.slice(0, 2) + "·" + licensePlate.slice(2);
}

export function carFilter(value) {
  if (value) {
    try {
      return insertDotInLicensePlate(value);
    } catch (error) {
      console.log("🚀 ~ file: userList.vue:373 ~ error:", error);
    }
    return value;
  }
  return value;
}
