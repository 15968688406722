<template>
  <div class="alarm-management-detail">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="设备上报" name="S"></el-tab-pane>
      <el-tab-pane label="平台告警" name="P"></el-tab-pane>
    </el-tabs>
    <div class="main">
      <MainSearchBar
        @search="searchData"
        @reset="resetSearch"
        :isExpendShow="false"
      >
        <template #form>
          <el-form
            ref="formRef"
            :model="searchForm"
            label-width="auto"
            class="search-form-layout"
          >
            <el-form-item label="告警名称" prop="name">
              <el-input
                placeholder="请输入"
                v-model="searchForm.name"
                maxlength="50"
              ></el-input>
            </el-form-item>
            <el-form-item label="告警码" prop="code">
              <el-input
                placeholder="请输入"
                v-model="searchForm.code"
                maxlength="50"
              ></el-input>
            </el-form-item>
            <el-form-item label="设备类型" prop="deviceType">
              <el-select
                clearable
                v-model="searchForm.deviceType"
                placeholder="请选择"
                style="width: 100%;"
              >
                <el-option
                  v-for="item in DEVICE_TYPE"
                  :label="item.label"
                  :value="item.value"
                  :key="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </template>
      </MainSearchBar>
      <div class="content-box">
        <el-table
          ref="multipleTable"
          row-key="id"
          :data="tableData"
          style="width: 100%"
          v-loading="tableLoading"
          :header-cell-style="{
            background: '#f7f8fa',
            color: '#000000',
            fontSize: '14px',
          }"
        >
          <el-table-column
            type="index"
            label="序号"
            align="left"
            width="60"
            fixed
          ></el-table-column>
          <el-table-column
            prop="name"
            label="告警名称"
            :align="tableAlign"
            min-width="180"
            fixed
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="deviceType"
            label="设备类型"
            :align="tableAlign"
            min-width="120"
            fixed
            show-overflow-tooltip
          >
            <template slot-scope="{ row }">
              {{ deviceTypeFormatter(row.deviceType) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="level"
            label="告警等级"
            :align="tableAlign"
            min-width="120"
            fixed
            show-overflow-tooltip
          >
            <template slot-scope="{ row }">
              <PlainTag
                :color="alarmLevelColorFormatter(row.level)"
                :bgColor="alarmLevelBgColorFormatter(row.level)"
                :text="alarmLevelFormatter(row.level)"
              ></PlainTag>
            </template>
          </el-table-column>
          <el-table-column
            prop="code"
            label="告警码"
            :align="tableAlign"
            min-width="120"
            fixed
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="description"
            label="告警说明"
            :align="tableAlign"
            min-width="120"
            fixed
            show-overflow-tooltip
          >
            <template slot-scope="{ row }">
              {{ row.description || '-' }}
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            :align="tableAlign"
            width="120"
            fixed="right"
          >
            <template slot-scope="scope">
              <div class="editCell">
                <el-button
                  type="text"
                  size="mini"
                  @click="handleEdit(scope.row)"
                  >编辑</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          style="margin-top: 10px"
          :background="false"
          :total="pageParams.total"
          :page.sync="pageParams.pageNum"
          :limit.sync="pageParams.pageSize"
          @pagination="handleCurrentChange"
          :layout="'total, -> ,prev, pager, next, sizes, jumper'"
        />
      </div>
    </div>
    <settingEditDialog
      ref="settingEditDialogRef"
      @change="handleEditDialogChange"
    ></settingEditDialog>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import MainSearchBar from '@/components/MainSearchBar/index';
import Pagination from '@/components/Pagination/index';
import { getSettingList } from '@/api/alert';
import { DEVICE_TYPE, ALARM_LEVEL, findLabelByValue } from '@/utils/const';
import PlainTag from '@/components/PlainTag/index.vue';
import settingEditDialog from './components/settingEditDialog.vue';

// defineOptions({
//   name: 'alarmManagementSetting',
// });

function deviceTypeFormatter(val) {
  return findLabelByValue(DEVICE_TYPE, val);
}

function alarmLevelFormatter(val) {
  return findLabelByValue(ALARM_LEVEL, val);
}
function alarmLevelBgColorFormatter(val) {
  return findLabelByValue(ALARM_LEVEL, val, 'bgColor');
}
function alarmLevelColorFormatter(val) {
  return findLabelByValue(ALARM_LEVEL, val, 'color');
}

let activeName = ref('S');
function handleClick(tab, event) {
  searchData();
}

const pageParams = ref({
  pageNum: 1,
  pageSize: 20,
  total: 0,
});
const orderStateList = ref([
  { label: '全部', value: '' },
  { label: '未处理', value: '1' },
  { label: '已处理', value: '2' },
]);

const tableAlign = ref('left');
const searchForm = ref({
  name: '',
  code: '',
  deviceType: '',
});
function searchData() {
  pageParams.value.pageNum = 1;
  getPages();
}

function handleCurrentChange({ page }) {
  pageParams.value.pageNum = page;
  getPages();
}

const formRef = ref();
function resetSearch() {
  formRef.value.resetFields();
  pageParams.value.pageNum = 1;
  getPages();
}

const tableLoading = ref(false);
const tableData = ref([]);
function getPages() {
  tableLoading.value = true;
  let params = {
    pageNo: pageParams.value.pageNum,
    pageSize: pageParams.value.pageSize,
    alertType: activeName.value,
    ...searchForm.value,
  };
  getSettingList(params)
    .then((res) => {
      tableLoading.value = false;
      tableData.value = res.data.records;
      pageParams.value.total = res.data.total;
    })
    .catch((e) => {
      tableLoading.value = false;
    });
}
getPages();

const settingEditDialogRef = ref();
function handleEdit(row) {
  settingEditDialogRef.value.open(row);
}

function handleEditDialogChange() {
  pageParams.value.pageNum = 1;
  getPages();
}
</script>

<style lang="scss" scoped>
@mixin flex($direction: row, $align-items: flex-start) {
  display: flex;
  flex-direction: $direction;
  align-items: $align-items;
}
.main {
  @include flex(column);
  gap: 12px;
  padding-top: 0 !important;
}
::v-deep .el-tabs {
  margin-right: 0;
  .el-tabs__nav-scroll {
    background-color: #fff;
    padding-left: 16px;
  }
  .el-tabs__nav-wrap::after {
    height: 1px;
  }

  .el-tabs__item {
    color: rgba(78, 89, 105, 1);
    &.is-active {
      color: var(--current-color);
    }
  }
}
.content-box {
  width: 100%;
}
.alarm-management-detail {
  display: flex;
  height: 100%;
  flex-direction: column;
}
</style>
